import React, { createContext, useMemo, useState } from 'react';
import {
  NavigationProps,
  GENERAL_MODULES,
  PORTFOLIO_COMPANY_MODULES_SECTION_1,
  PORTFOLIO_COMPANY_MODULES_SECTION_2,
  REGULAR_COMPANY_MODULES_SECTION_1,
  REGULAR_COMPANY_MODULES_SECTION_2,
  GROUP_COMPANY_MODULES_SECTION_1,
  GROUP_COMPANY_MODULES_SECTION_2,
  AccessFlags,
} from '.';
import { useCompanyType, CompanyType, useCurrentCompany } from 'utils/hooks';
import { VStack, HStack, StackProps, Stack } from '@chakra-ui/react';
import { SideMenu, TopMenu } from './pieces';
import { useFeatureFlags, useUserActions, useUserSetting } from './Navigation.hooks';
import { HelpMenu } from 'Organisms/HelpMenu';
import { DowntimeNotification } from 'Molecules/DowntimeNotification/DowntimeNotification';
import { format } from 'date-fns';

const LayoutWrapper = (props: StackProps) => (
  <VStack
    {...props}
    flexGrow="1"
    bg="bg.default"
    marginInlineStart="0px !important"
    marginStart="0px !important"
    gap="0px"
    overflow="auto"
  />
);
const PageWrapper = (props: StackProps) => (
  <HStack {...props} height="100vh" width="100vw" gap="0px" />
);

export const GlobalContext = createContext({
  walktourActive: false,
  showWalktourTooltip: false,
  helpCenterOpen: false,
  showInfoBoxes: false,
  setWalktourActive: (_val: boolean) => {},
  setHelpCenterOpen: (_val: boolean) => {},
  setShowInfoBoxes: (_val: boolean) => {},
  setShowWalktourTooltip: (_val: boolean) => {},
});

export const MAINTENANCE_DATES = {
  startTime: new Date('2025-01-14T10:00:00'),
  endTime: new Date('2025-01-19T20:00:00'),
};

export const Navigation = ({ children }: NavigationProps) => {
  const { languageEditor } = useUserActions();
  const { companyType, isInGroups } = useCompanyType();
  const { company } = useCurrentCompany();
  const [helpCenterOpen, setHelpCenterOpen] = useState(false);
  const [walktourActive, setWalktourActive] = useState(false);
  const [showInfoBoxes, setShowInfoBoxes] = useState(false);
  const [showWalktourTooltip, setShowWalktourTooltip] = useState(false);

  const formattedStartTime = format(MAINTENANCE_DATES.startTime, 'yyyy-MM-dd_HH-mm');
  const formattedEndTime = format(MAINTENANCE_DATES.endTime, 'yyyy-MM-dd_HH-mm');

  const [showDowntimeNotification, setShowDowntimeNotification] = useUserSetting(
    `show-downtime-notification-${formattedStartTime}-${formattedEndTime}`,
    true
  );

  const {
    assessments: hasTaxonomyAccess,
    pai: hasPaiAccess,
    esrs: hasESRSAccess,
  } = useFeatureFlags({ company });

  const sideMenuItems = useMemo(() => {
    if (companyType === CompanyType.regular) {
      return [
        ...REGULAR_COMPANY_MODULES_SECTION_1.filter((section) => {
          if (section.key === AccessFlags.ESRS && !hasESRSAccess) {
            return false;
          }
          if (section.key === AccessFlags.PAI && !hasPaiAccess) {
            return false;
          }
          if (section.key === AccessFlags.TAXONOMY && !hasTaxonomyAccess) {
            return false;
          }
          return true;
        }),
        ...REGULAR_COMPANY_MODULES_SECTION_2.filter((section) =>
          section.key === 'groups' ? isInGroups : true
        ),
        ...GENERAL_MODULES,
      ];
    } else if (companyType === CompanyType.portfolio) {
      return [
        ...PORTFOLIO_COMPANY_MODULES_SECTION_1,
        ...PORTFOLIO_COMPANY_MODULES_SECTION_2,
        ...GENERAL_MODULES,
      ];
    }
    return [
      ...GROUP_COMPANY_MODULES_SECTION_1.filter((section) => {
        if (section.key === AccessFlags.TAXONOMY && !hasTaxonomyAccess) {
          return false;
        }
        if (section.key === AccessFlags.ESRS && !hasESRSAccess) {
          return false;
        }
        return true;
      }),
      ...GROUP_COMPANY_MODULES_SECTION_2.filter((section) =>
        section.key === 'groups' ? isInGroups : true
      ),
      ...GENERAL_MODULES,
    ];
  }, [companyType, isInGroups, company, hasTaxonomyAccess, hasPaiAccess, hasESRSAccess]);

  const context = {
    walktourActive: walktourActive,
    setWalktourActive: setWalktourActive,
    helpCenterOpen: helpCenterOpen,
    setHelpCenterOpen: setHelpCenterOpen,
    showInfoBoxes: showInfoBoxes,
    setShowInfoBoxes: setShowInfoBoxes,
    showWalktourTooltip: showWalktourTooltip,
    setShowWalktourTooltip: setShowWalktourTooltip,
  };

  return (
    <VStack alignItems="stretch" gap="0px">
      {showDowntimeNotification && (
        <DowntimeNotification
          startTime={MAINTENANCE_DATES.startTime}
          endTime={MAINTENANCE_DATES.endTime}
          onClose={() => setShowDowntimeNotification(false)}
        />
      )}
      <PageWrapper alignItems="stretch" bg="red">
        <GlobalContext.Provider value={context}>
          <SideMenu items={sideMenuItems} />
          <LayoutWrapper>
            <TopMenu />
            <Stack
              mt="0px !important" // LayoutWrapper should be a box: https://github.com/chakra-ui/chakra-ui/issues/2578
              width="100%"
              overflowX="auto"
              flexGrow="1"
            >
              {children}
            </Stack>
            {languageEditor}
          </LayoutWrapper>
          <HelpMenu />
        </GlobalContext.Provider>
      </PageWrapper>
    </VStack>
  );
};
